<template>
  <div>
    <div v-if="result_list != null">
      <v-card
        class="mx-auto pa-6 ma-4"
        max-width="1000"
        outlined
        raised
        elevation="2"
        v-for="(value, key) in result_list"
        :key="key"
      >
        <v-container>
          <v-row>
            <v-img :src="type_desc[value.type_name]" max-width="100"></v-img>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
              <div>{{ $t("message.resultsPage.date") }}</div>
              <div class="date">
                {{ $formatDateISO8601(value.draw_date.toDate()) }}
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="4"
              lg="3"
              xl="3"
              class="div_ball"
            >
              <div>{{ $t("message.resultsPage.results") }}</div>
              <div>
                <span
                  class="regular_ball"
                  v-for="(value, key) in value.numbers"
                  :key="key"
                  >{{ value }}</span
                >
                <span :class="'extra_ball ' + value.type_name">{{
                  value.extra_number[0]
                }}</span>
              </div>
              <div class="multiplier_box">
                <span
                  :class="value.type_name + ' multiplier rounded elevation-3 '"
                  >{{
                    $t("message.resultsPage.multiplier." + value.type_name, {
                      num: value.multiplier,
                    })
                  }}</span
                >
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
              <div>{{ $t("message.resultsPage.jackpot") }}</div>
              <div class="text-h5 font-weight-black">
                {{ $formatMoneyToWord(value.jackpot, "US$", "") }}
              </div>
              <div>
                {{
                  $t(
                    "message.resultsPage." +
                      (value.won == "yes" ? "won" : "rollover")
                  )
                }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-container>
      <div>
        <horizontal-scroll class="horizontal-scroll">
          <div class="outer">
            <v-card
              :class="
                value.month != undefined
                  ? 'year_month_box'
                  : 'month_box primary'
              "
              @click="click_month(value, key)"
              v-for="(value, key) in footer_months"
              :key="key"
            >
              <div v-if="value.month != undefined">
                <div>
                  {{
                    $t(
                      "message.monthsShortNames." + (value.month + 1).toString()
                    )
                  }}
                </div>
                <div>{{ value.year }}</div>
              </div>
              <div v-else>
                <div>{{ value.year }}</div>
              </div>
            </v-card>
          </div>
        </horizontal-scroll>
      </div>
    </v-container>
  </div>
</template>
<style scoped>
.outlined {
  border: 2px solid;
  border-radius: 50%;
  border-color: black;
}
.regular_ball {
  display: table-cell;
  vertical-align: middle;
  border-radius: 50%;
  text-align: center;
  width: 35px;
  height: 35px;
  font-weight: 900;
  font-size: 1.2em;
  color: #000;
  background: #fff;
  border: 1px solid #000;
}
.extra_ball {
  display: table-cell;
  vertical-align: middle;
  border-radius: 50%;
  text-align: center;
  width: 35px;
  height: 35px;
  font-weight: 900;
  font-size: 1.2em;
  color: white;
}
.div_ball {
  min-width: 230px;
}
.date {
  color: black;
  font-weight: 900;
  font-size: 1.2em;
}
.outer {
  display: flex;
  flex: 1;
  width: auto;
  height: 100%;
  padding: 0 20px;
  flex-flow: row nowrap;
  align-items: center;
}

.horizontal-scroll {
  display: flex;
  width: 100%;
  height: 150px;
}

.month_box {
  border: solid 1px #2c3e50;
  padding: 10px;
  margin: 10px;
  font-weight: 900;
  font-size: 1.2em;
  height: 70px;
  color: white;
}
.year_month_box {
  border: solid 1px #2c3e50;
  padding: 10px;
  margin: 10px;
  font-weight: 900;
  font-size: 1.2em;
  height: 70px;
}

.multiplier_box {
  margin-top: 3px;
}
.multiplier {
  color: white;
  padding: 2px;
  font-size: 1.2em;
}
.megamillions {
  background: #004ca6;
  border: 1px solid #004ca6;
}
.powerball {
  background: #e21e25;
  border: 1px solid #e21e25;
}
</style>

<script>
import { mapGetters } from "vuex";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  computed: {
    ...mapGetters(["getUser", "myTickets", "lotteries_info"]),
  },
  data() {
    return {
      now: new Date(),
      result_list: [],
      type_name: "powerball",
      type_desc: {
        powerball: "/static/images/powerball_mini.png",
        megamillions: "/static/images/megamillions_mini.png",
      },
      footer_months: [],
    };
  },
  mounted() {
    const d = new Date();
    var month = d.getMonth();
    var year = d.getFullYear();
    this.fetch_result(month + 1, year).then(() => {
      if (this.result_list.length == 0) {
        month--;
        if (month < 0) {
          month == 11;
          year--;
        }
        this.fetch_result(month + 1, year);
      }
    });
    this.init_footer();
  },
  methods: {
    fetch_result(month, year) {
      return new Promise((resolve) => {
        var doc_id = "";
        var month_str = ("0" + month).slice(-2);
        doc_id += year.toString() + "-" + month_str;

        const historyResultsDocRef = doc(
          getFirestore(),
          "history_results",
          doc_id
        );
        getDoc(historyResultsDocRef).then((doc_snapshot) => {
          var local_result = [];
          var data = doc_snapshot.data();
          for (var result in doc_snapshot.data()) {
            local_result.push(data[result]);
          }
          this.result_list = local_result.reverse();
          window.scrollTo(0, 0);
          resolve();
        });
      });
    },
    init_footer() {
      var result = [];
      for (var i = this.now.getFullYear(); i >= 2006; --i) {
        result.push({
          year: i,
          open: i == this.now.getFullYear() ? true : false,
        });
        if (i == this.now.getFullYear()) {
          for (var j = this.now.getMonth(); j >= 0; --j) {
            result.push({
              year: i,
              month: j,
            });
          }
        }
      }
      this.footer_months = result;
    },
    click_month(month_box, index) {
      // Load history for month
      if (month_box.month != undefined) {
        this.fetch_result(month_box.month + 1, month_box.year);
      }
      // Expand footer
      else {
        if (month_box.open) {
          this.footer_months = this.footer_months.filter(
            (box) => box.year != month_box.year || box.month == undefined
          );
          month_box.open = false;
        } else {
          var startMonth = 0;
          var endMonth = 11;
          if (month_box.year == 2006) {
            startMonth = 4;
          }
          if (month_box.year == this.now.getFullYear()) {
            endMonth = this.now.getMonth();
          }
          for (var j = startMonth; j <= endMonth; ++j) {
            this.footer_months.splice(index + 1, 0, {
              year: month_box.year,
              month: j,
            });
          }
          month_box.open = true;
        }
      }
    },
  },
};
</script>